import React from "react";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product
import TermsOfServicePage from "../components/Documents/TermsOfServicePage.jsx";

export default () => (
  <TermsOfServicePage />
);
