import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import SEO from 'react-seo-component'

import pricingPageStyle from "assets/jss/material-kit-react/views/pricingPage.jsx";

class TermsOfServicePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    const siteTitle = "Terms of Service";
    const description = "Categle Terms of Service."
    const siteName = "Categle"       
    return (
      <div>
        <SEO 
            title={siteTitle} 
            description={description} 
            titleTemplate={siteName} 
            author="Categle" />          
        <Header
          color="transparent"
          brand="Categle.com"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div>
                    <div className={classes.name}>
                    <h1>Categle Terms and Conditions of Use</h1>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.description}>
              

<h2>1. Terms</h2>

<p>By accessing this Website, accessible from www.categle.com, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>

<h2>2. Use License</h2>

<p>Permission is granted to temporarily download one copy of the materials on Categle's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

<ul>
    <li>modify or copy the materials;</li>
    <li>use the materials for any commercial purpose or for any public display;</li>
    <li>attempt to reverse engineer any software contained on Categle's Website;</li>
    <li>remove any copyright or other proprietary notations from the materials; or</li>
    <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
</ul>

<p>This will let Categle to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>

<h2>3. Disclaimer</h2>

<p>All the materials on Categle’s Website are provided "as is". Categle makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Categle does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

<h2>4. Limitations</h2>

<p>Categle or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Categle’s Website, even if Categle or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

<h2>5. Revisions and Errata</h2>

<p>The materials appearing on Categle’s Website may include technical, typographical, or photographic errors. Categle will not promise that any of the materials in this Website are accurate, complete, or current. Categle may change the materials contained on its Website at any time without notice. Categle does not make any commitment to update the materials.</p>

<h2>6. Links</h2>

<p>Categle has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Categle of the site. The use of any linked website is at the user’s own risk.</p>

<h2>7. Site Terms of Use Modifications</h2>

<p>Categle may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

<h2>8. Your Privacy</h2>

<p>Please read our <a href="/privacy-policy/">Privacy Policy</a>.</p>

<h2>9. Governing Law</h2>

<p>Any claim related to Categle's Website shall be governed by the laws of the Washington, USA without regards to its conflict of law provisions.</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(pricingPageStyle)(TermsOfServicePage);
